// User services mock data

import { userBuilders } from "artisn/builders";
import { AtnUser } from "types/atn/atnUser.types";

const { buildUser } = userBuilders;
const baseUser = buildUser();

export const mockUser: AtnUser = {
  email: baseUser.email,
  firstName: baseUser.name,
  lastName: baseUser.lastname,
  userType: "Admin",
  phone: "",
  document: "",
  documentType: "CI",
  termsAccept: false
};

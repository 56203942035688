// User services
import { mockUser } from "./user.service.mock";
import { shouldMock } from "utils/common.utils";
import {
  createUser8baseQuery,
  deleteUserMutation
} from "./user.service.graphql";
import { updateUserTermsMutation } from "./user.service.graphql";
import { fetchCurrentUserQuery } from "./user.service.graphql";
import { FILE_UPLOAD_PARAMETERS } from "./user.service.graphql";
import axiosGqlDefault, { axiosHandler } from "utils/axiosgql.utils";
import { buildHeaders } from "utils/services.utils";
import { AtnUser } from "types/atn/atnUser.types";
import { userSignUpMutationPayload } from "./user.service.types";
import { FileStackCredentialsResponse } from "./user.service.types";
import { FileStackCredentials, FileStackResponse } from "./user.service.types";
import CONSTANTS from "config/constants";

const { API } = CONSTANTS;
const { AUTHENTICATION_PROFILE } = API;

export const fetchCurrentUser = async (): Promise<AtnUser> => {
  const artisnHeaders = await buildHeaders();

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: fetchCurrentUserQuery,
          variables: {}
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data.user;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockUser);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const userSignUpMutation = async (
  payload: userSignUpMutationPayload
): Promise<AtnUser> => {
  const artisnHeaders = await buildHeaders();
  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: createUser8baseQuery,
          variables: {
            user: payload,
            authProfileId: AUTHENTICATION_PROFILE
          }
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockUser);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getFileStackCredentials =
  async (): Promise<FileStackCredentials> => {
    const artisnHeaders = await buildHeaders();

    const response = await axiosGqlDefault.post<FileStackCredentialsResponse>(
      "/",
      {
        query: FILE_UPLOAD_PARAMETERS
      },
      {
        headers: {
          authorization: artisnHeaders.Authorization
        }
      }
    );

    const { data } = response.data ?? {};
    return data;
  };

export const uploadFile8Base = async (
  body: FormData,
  credentials: FileStackCredentials
): Promise<FileStackResponse> => {
  const { fileUploadInfo } = credentials;
  const { apiKey, policy, signature, path } = fileUploadInfo;
  const url = [
    `https://www.filestackapi.com/api/store/S3`,
    `?key=${apiKey}`,
    `&policy=${policy}`,
    `&signature=${signature}`,
    `&path=${path}`
  ].join("");
  const response = await axiosHandler.post<Promise<FileStackResponse>>(
    url,
    body
  );
  const { data } = response ?? {};
  return data;
};

export const fetchCurrentUserSSR = async (
  token: string | undefined
): Promise<AtnUser> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: fetchCurrentUserQuery,
          variables: {}
        },
        {
          headers: {
            authorization: token ?? ""
          }
        }
      );

      return data.data.user;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockUser);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const updateUserTerms = async (payload: AtnUser): Promise<AtnUser> => {
  const artisnHeaders = await buildHeaders();
  const { id, termsAccept } = payload;
  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: updateUserTermsMutation,
          variables: {
            termsAccept: termsAccept,
            filter: {
              id: id
            }
          }
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockUser);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const deleteUser = async (id: string): Promise<string> => {
  // console.log({ id });
  const artisnHeaders = await buildHeaders();
  console.log({ artisnHeaders });
  try {
    const { data } = await axiosGqlDefault.post(
      "",
      {
        query: deleteUserMutation,
        variables: {
          data: {
            id,
            force: true
          }
        }
      },
      {
        headers: {
          authorization: artisnHeaders.Authorization
        }
      }
    );

    console.log({ data });

    return data.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

// user graphql queries and mutations

export const fetchCurrentUserQuery = `
  query CurrentUser {
    user {
      id
      email
      firstName
      lastName
      userType
      phone
      document
      documentType
      termsAccept
    }
  }
`;

export const createUser8baseQuery = `
  mutation UserSignUp($user: UserCreateInput!, $authProfileId: ID) {
    userSignUpWithToken(user: $user, authProfileId: $authProfileId) {
      id
      email
    }
  }
`;

export const FILE_UPLOAD_PARAMETERS = `
  {
    fileUploadInfo {
      policy
      signature
      apiKey
      path
    }
  }
`;

export const updateUserTermsMutation = `
  mutation userUpdate($termsAccept: Boolean, $filter: UserKeyFilter) {
    userUpdate(data: {termsAccept: $termsAccept}, filter:$filter){
      id
      termsAccept
      email
    }
  }
`;

export const deleteUserMutation = `
  mutation UserDelete($data: UserDeleteInput) {
    userDelete(force:true, data: $data) {
      success
    }
  }
`;

import { useQuery, useMutation, useQueryClient } from "react-query";

import { fetchCurrentUser, updateUserTerms } from "./user.service";
import { userSignUpMutation, deleteUser } from "services/atn/user/user.service";
import useAuth from "contexts/auth/auth.context.hooks";

export const useFetchCurrentUser = () => {
  const { isAnonymous } = useAuth();

  return useQuery(["currentUser"], () => fetchCurrentUser(), {
    enabled: !isAnonymous
  });
};

export const useUserSignUp = () => {
  const queryClient = useQueryClient();

  return useMutation(userSignUpMutation, {
    onSettled: () => {
      queryClient.invalidateQueries(["currentUser"]);
    }
  });
};

export const useUpdateUserTerms = () => {
  const queryClient = useQueryClient();

  return useMutation(updateUserTerms, {
    onSettled: () => {
      queryClient.invalidateQueries(["currentUser"]);
    }
  });
};

export const useDeleteCurrentUser = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteUser, {
    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(["currentUser"]);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["currentUser"]);
    }
  });
};
